import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import $ from 'jquery'

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  actionSaveOpenHideSiderBar
} from '../../../../redux/home/actions'

export function Brand() {
  const uiService = useHtmlClassService();

  const dispatch = useDispatch();
  const { isShowSiderbar } = useSelector(state => ({
    isShowSiderbar: state.homeReducer.isShowSiderbar
  }), shallowEqual)

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  const onChangeSiderbar = () => {
    if (isShowSiderbar) {
      $("#kt_body").addClass("aside-minimize");
      $(".projectName").addClass("d-none");
    }
    else {
      $("#kt_body").removeClass("aside-minimize");
      $(".projectName").removeClass("d-none");
    }
    dispatch(actionSaveOpenHideSiderBar(!isShowSiderbar))
  }

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          {/* <img alt="logo" src={layoutProps.headerLogo}/> */}
          <span className="projectName">TRADE</span>
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button
              onClick={onChangeSiderbar}
              className={`brand-toggle btn btn-sm px-0 ${!isShowSiderbar ? 'active' : 'false'}`}
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")} />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
